<template>
  <div>
    <EmployeeAttendance is-load-all-employments enable-export/>
  </div>
</template>

<script>
import EmployeeAttendance from "./EmployeeAttendance";
export default {
  components: {EmployeeAttendance}
}
</script>

<style scoped>

</style>